import { Link } from "gatsby";
import React from "react";
import { Box } from "../elements/Box";
import { Flex } from "../elements/Flex";
import HeroSection from "../components_v2/HeroSection";
import ImageTextSection from "../components_v2/ImageTextSection";
import SubscribeCards from "../components_v2/SubscribeCards";
// import WelcomeCards from "../components_v2/WelcomeCards";
import AppStatistics from "../components_v2/AppStatistics";
import ClippedPathContainer from "../components_v2/ClippedPathContainer";
import SectionHeader from "../components_v2/SectionHeader";
import FreeGuidesForm from "../components_v2/FreeGuidesForm";
import FaqAccordion from "../components_v2/FaqAccordion";
import IconWithText from "../components_v2/IconWithText";
import { Button } from "../elements/Button";
import SEO from "../components/SEO";
// import { PRICES } from "../utils/constants";

import { Grid } from "../elements/Grid";
import { Image } from "../elements/Image";

// IMAGES
import resultsImg from "../images/app/GET-ME-RESULTS.gif";
import leanWeb from "../images/app/workout-anywhere.gif";
import personalisedImg from "../images/app/PERSONALISED-GUIDES-AND-MEAL-PLANS.gif";
import realTimeWorkoutImage from "../images/app/FRESH-CONTENT.gif";
import habits from "../images/v2/app/habits.png";
import delicious from "../images/app/DELICIOUS-HEALTHY-RECIPES.gif";
// import phoneIcon from "../images/v2/svgs/icons/Phone.svg";
// import tvIcon from "../images/v2/svgs/icons/TV.svg";
// import laptopIcon from "../images/v2/svgs/icons/Laptop.svg";
import calendarIcon from "../images/v2/svgs/icons/Calendar2.svg";
import targetIcon from "../images/v2/svgs/icons/Target.svg";
import timeIcon from "../images/v2/svgs/icons/Time.svg";
import graphIcon from "../images/v2/svgs/icons/Graph.svg";
import recipeIcon from "../images/v2/svgs/icons/Recipe.svg";
import recipeIcon2 from "../images/v2/svgs/icons/Recipe2.svg";
import notesIcon from "../images/v2/svgs/icons/Notes.svg";

// import bfApp from "../images/v2/banners/black-friday/bf-app.mp4";
// import bfKit from "../images/v2/banners/black-friday/bf-kit.mp4";
// import bfStudent from "../images/v2/banners/black-friday/bf-student.mp4";
// import bfSupplement from "../images/v2/banners/black-friday/bf-supplement.mp4";

// import heroDesktop from "../images/slideshow/home/1.jpg";
// import heroMobile from "../images/slideshow/home/1mobile.jpg";

import image1 from "../images/new-banners/desktop/NEW-5.jpg";
import image2 from "../images/new-banners/desktop/NEW-1.jpg";

import imageMobile1 from "../images/new-banners/mobile/NEW-5.jpg";
import imageMobile2 from "../images/new-banners/mobile/NEW-1.jpg";

import appdesktop from "../images/videos/banner-desktop.mp4";
import appmobile from "../images/videos/banner-mobile.mp4";

const AppPage = () => {
  return (
    <>
      <SEO title="App" />

      <HeroSection
        slideshow={{
          desktop: [{ type: "video", src: appdesktop }, image1, image2],
          mobile: [{ type: "video", src: appmobile }, imageMobile1, imageMobile2],
        }}
        // title="KEEPING IT LEAN."
        // subtitle="Everything you need to achieve the results you dream of."
        // cta="UP TO 40% OFF MEMBERSHIP"
        // path="/join"
        // mobileImg={heroMobile}
        // desktopImg={heroDesktop}
        // ctaDescription={`then ${PRICES.monthlyPrice} on a monthly plan`}
        // ukDesktopImg="https://www.proudtocaredevon.org.uk/wp-content/uploads/sites/4/2021/08/y9DpT.jpg"
        // ukMobileImg="https://www.sheroamssolo.com/wp-content/uploads/2019/05/image-placeholder-350x350.png"
        // usDesktopImg="https://www.proudtocaredevon.org.uk/wp-content/uploads/sites/4/2021/08/y9DpT.jpg"
        // usMobileImg="https://www.sheroamssolo.com/wp-content/uploads/2019/05/image-placeholder-350x350.png"
        // mobileVideo={bfAppMobile}
        // desktopVideo={bfAppDesktop}
        // videoSlideShow={[bfApp, bfKit, bfStudent, bfSupplement]}
      />

      <ImageTextSection
        header={<AppStatistics />}
        title="GET ME RESULTS"
        description="Familia, we all have different goals and reasons for working out which is why the LEAN App has been designed to be totally personalized to you."
        cta="JOIN NOW"
        path="/join"
        reverse
        img={resultsImg}
        imgStyle={{ maxHeight: "80vh" }}
      />

      <ImageTextSection
        title="WORKOUT ANYWHERE"
        description={[
          "Watch and access all LEAN content from anywhere and up to 4K resolution. Hook it up to your laptop, cast to your TV or whatever is easiest to help you work out across devices! Easily search for all classes - so you can get straight to working up a sweat!",
        ]}
        cta="JOIN NOW"
        path="/join"
        children={
          <Flex color="darkTeal" mt={3} flexDirection={["column", null, "row"]} justifyContent="space-between"></Flex>
        }
        img={leanWeb}
      />

      <Grid
        display={["block", "block", "grid"]}
        maxWidth="1100px"
        margin="0 auto"
        gridTemplateColumns={"1fr 1fr"}
        gridGap={[4, 4, 5]}
        px={3}
        pt={[4, 4, 5]}
        overflow="hidden"
      >
        <Box>
          <Flex height="100%" flexDirection="column">
            <Flex height="100%" alignItems={"flex-start"}>
              <SectionHeader
                title={"Personalised guides and meal plans"}
                description={
                  "Reach your goals with LEAN workout guides and meal plans tailored totally to you. Choose between 3 - 7 classes a week and workout along with me. There are 100’s of classes designed to get you sweating and feeling the burrrrrrn familia, they’re suitable for any and all levels, with progressions and regressions for all movements and the ability to train at home or in the gym!"
                }
                textAlign={"left"}
                cta={"JOIN NOW"}
                path={"/join"}
                mt={5}
              >
                <Flex color="darkTeal" mt={3} flexDirection={["column", null, "row"]} justifyContent="space-between">
                  <IconWithText icon={calendarIcon} text="Flexible to your schedule" />
                  <IconWithText icon={targetIcon} text="Helps you reach personal goals" />
                </Flex>
              </SectionHeader>
            </Flex>
          </Flex>
        </Box>

        <Flex position="relative" style={{ order: -1 }}>
          <Image borderRadius={"10px"} src={personalisedImg} style={{ maxHeight: "80vh" }} />
        </Flex>
      </Grid>

      <ClippedPathContainer bg="darkTeal">
        <ImageTextSection
          title="FRESH CONTENT DROPPED EVERY WEEK"
          description="Brand new guides designed with your goals in mind added every single week familia.! Fancy levelling up your workout even more? You’ll also find brand new fiyaaaah challenges to get stuck into every month. And of course we have regular new recipe drops so you always have new yummy meals to fall in love with. "
          img="https://www.sheroamssolo.com/wp-content/uploads/2019/05/image-placeholder-350x350.png"
          color="darkTeal"
          pb="0"
          alignItems="flex-start"
          img={realTimeWorkoutImage}
          absoluteImg
          pb={[8, 8, 7]}
          children={
            <Flex color="darkTeal" mt={3} flexDirection={["column", null, "row"]} justifyContent="space-between">
              <IconWithText color="white" icon={timeIcon} text="Flexible to your schedule" />
              <IconWithText color="white" icon={graphIcon} text="Helps you reach personal goals" />
            </Flex>
          }
        />
      </ClippedPathContainer>

      <SectionHeader title="Subscription plans" />

      <SubscribeCards />

      <ImageTextSection
        title="Delicious & Healthy Recipes"
        description="Find 100’s of recipes for Omnivore, Vegan, Vegetarians and Pescetarians on the app, all created by our sports nutritionist exclusively for LEAN! Customise your meal plan to your needs and use my educational videos to help answer all your questions about all things nutrition."
        cta="JOIN NOW"
        path="/join"
        reverse
        alignItems="flex-start"
        img="https://www.sheroamssolo.com/wp-content/uploads/2019/05/image-placeholder-350x350.png"
        children={
          <Flex color="darkTeal" mt={3} flexDirection={["column", null, "row"]} justifyContent="space-between">
            <IconWithText icon={recipeIcon} text="Delicious and healthy recipes" />
            <IconWithText icon={recipeIcon2} text="New recipes added frequently" />
          </Flex>
        }
        pb={[0, 0, 0]}
        img={delicious}
        imgStyle={{ maxHeight: "80vh" }}
      />

      <ClippedPathContainer bg="lilac">
        <ImageTextSection
          title="Track habit & progress"
          description="Familia, mental health is just as important as your physical health, which is why there’s a dedicated journaling section where you can track progress and write down your thoughts. The habit tracker will support you in creating new habits you want to form so you can start living your healthiest and happiest lives."
          img="https://www.sheroamssolo.com/wp-content/uploads/2019/05/image-placeholder-350x350.png"
          color="lilac"
          pb="0"
          children={
            <Flex color="white" mt={3} flexDirection={["column", null, "row"]} justifyContent="space-between">
              <IconWithText icon={graphIcon} text="Stay motivated by tracking habits" />
              <IconWithText icon={notesIcon} text="Use the journal for gratitude practice" />
            </Flex>
          }
          img={habits}
          absoluteImg
          pb={[8, 8, 7]}
          alignItems="center"
        />
      </ClippedPathContainer>

      <Box maxWidth="1000px" margin="0 auto" pt={4} pb={6} px={3}>
        <SectionHeader title="Commonly Asked Questions" />

        <FaqAccordion />

        <Link to="/join">
          <Button
            variant="rounded"
            bg={"darkTeal"}
            color={"white"}
            display="inline-block"
            style={{
              margin: "auto",
              marginTop: 50,
            }}
          >
            JOIN NOW
          </Button>
        </Link>
      </Box>

      <FreeGuidesForm />
    </>
  );
};

export default AppPage;
