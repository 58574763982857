import { Link } from "gatsby";
import React from "react";
import { Box } from "../elements/Box";
import { Button } from "../elements/Button";
import { Flex } from "../elements/Flex";
import { Grid } from "../elements/Grid";
import { Heading } from "../elements/Heading";
import { Image } from "../elements/Image";
import { Text } from "../elements/Text";
import calendar from "../images/v2/svgs/icons/Calendar.svg";
import { ACTIVE_SALE, PRICES, SALE_PRICES } from "../utils/constants";

const subscriptions = [
  {
    frequency: "Monthly",
    price: PRICES.monthlyPrice,
    saving: !ACTIVE_SALE ? null : SALE_PRICES.monthlySaving,
    salePrice: SALE_PRICES.monthlyPrice,
    color: "pink",
  },
  {
    frequency: "Quarterly",
    price: PRICES.quarterlyPrice,
    saving: !ACTIVE_SALE ? PRICES.quarterlySaving : SALE_PRICES.quarterlySaving,
    salePrice: SALE_PRICES.quarterlyPrice,
    color: "lilac",
  },
  {
    frequency: "Annually",
    price: PRICES.yearlyPrice,
    saving: !ACTIVE_SALE ? PRICES.yearlySaving : SALE_PRICES.yearlySaving,
    salePrice: SALE_PRICES.yearlyPrice,
    color: "darkTeal",
  },
];

const SubscribeCards = () => {
  return (
    <Grid
      maxWidth="1100px"
      gridGap={[3, 3, 4, 5]}
      p={3}
      margin="0 auto"
      display={["block", "block", "grid"]}
      gridTemplateColumns="repeat(3, 1fr)"
      textAlign="center"
    >
      {subscriptions.map(({ frequency, price, salePrice, saving, color }, index) => (
        <Flex
          transformSubCard={frequency === "Annually"}
          key={frequency}
          mt={[3, 3, 0]}
          position="relative"
          justifyContent="center"
          alignItems="center"
          py={5}
          px={3}
          borderRadius="30px"
          bg={color}
          flexDirection="column"
          color="white"
          style={{ order: frequency === "Annually" ? 0 : frequency === "Monthly" ? -1 : 1 }}
        >
          {saving && (
            <Flex
              flexDirection="column"
              position="absolute"
              top="-20px"
              right="-20px"
              height="75px"
              width="75px"
              bg="pink"
              color="white"
              borderRadius="50%"
              justifyContent="center"
              alignItems="center"
              fontSize={14}
            >
              <Text>{saving}%</Text>
              <Text textTransform="uppercase">saving</Text>
            </Flex>
          )}

          <Image width="30px" mb={2} src={calendar} />

          <Text fontSize={20} textTransform="uppercase">
            {frequency}
          </Text>

          <Flex alignItems="center" mt={2}>
            <Text lineThrough={ACTIVE_SALE && salePrice} fontWeight={600} fontSize={30}>
              £{price}
            </Text>

            {ACTIVE_SALE && salePrice && (
              <Text ml={2} fontWeight={600} fontSize={30}>
                £{salePrice}
              </Text>
            )}
          </Flex>

          <Text mt={3}>Billed {frequency}</Text>

          <Link to="/join">
            <Button mt={3} color={color} bg="white" px={3} variant="rounded">
              Subscribe now
            </Button>
          </Link>
        </Flex>
      ))}
    </Grid>
  );
};

export default SubscribeCards;
