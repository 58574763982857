import React from "react";
import { Flex } from "../elements/Flex";
import { Image } from "../elements/Image";
import { Text } from "../elements/Text";

const IconWithText = ({ icon, text, color }) => {
  return (
    <Flex color={color} my={[2, 2, 0]} alignItems="center" p={1}>
      <Image mr={3} src={icon} width="30px" />
      <Text>{text}</Text>
    </Flex>
  );
};

export default IconWithText;
