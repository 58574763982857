import React from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import {Text} from "../elements/Text";
import {Heading} from "../elements/Heading";
import {Box} from "../elements/Box";
import {Image} from "../elements/Image";
import {IMAGES} from "../utils/assets";

const faqs = [
  {
    question: "How much does the LEAN app cost?",
    answer: (
        <>
        <Text mb={3}>
      There are a number of subscription types to choose from. Our best value subscription is the Annual
      subscription, which will save you 33% of the full price.
    </Text>
          <Heading fontSize={18} mb={3}>
            Subscription types:
          </Heading>

          <Box ml={4} mb={4}>
            <ul>
              <li>Monthly: £5.99, $8.99, 6.99€</li>
              <li>Quarterly: £14.99, $21.99, 17.99€</li>
              <li>Annually: £49.99, $69.99, 59.99€</li>
            </ul>
          </Box>
        </>
        )
  },
  {
    question: "Can I use the LEAN app in other countries?",
    answer: (
        <Text mb={4}>
          YES! Subscriptions are available <strong>WORLDWIDE</strong> in your local currency.
        </Text>
    )
  },
  {
    question: "Can I cast from the LEAN app to my TV?",
    answer: (
        <Text mb={3}>
          Yes! For casting on iPhones, you cast via the AirPlay button and on Android you can use your phone's native
          screen Mirroring functionality. Couple of clicks and you're on the big screen.
        </Text>
    )
  },
  {
    question: "What’s in the LEAN app?",
    answer: (
        <>
        <Text mb={3}>EVERYTHING you wanted, plus things you never knew you did, including:</Text>

        <Box ml={4} mb={3}>
          <ul>
            <li>Personalized fitness plans</li>
            <li>Guided workout classes to train alongside Lilly in the gym or at home</li>
            <li>Workout guides structured around your goals and abilities</li>
            <li>A HUGE library of physio & how-to videos</li>
            <li>Personalized meal plans</li>
            <li>100’s of recipes recipes at your fingertips all designed by our sports nutritionist</li>
            <li>Habit tracker to keep you on track and motivated</li>
            <li>Journaling to boost your mind and confidence</li>
            <li>LEAN store with exclusive early access to your fav products</li>
          </ul>
        </Box>

        <Text mb={3}>
          As you know Lilly is a professional physio, fitness and health coach with 2.8 million women already in our
          Familia, so we didn’t want to let you down and have worked really hard to bring you something extra special.
          All the content, workouts and tools you need to take control, feel confident and be amazing.
        </Text>

        <Text mb={3}>A little more detail about what you’ll get:</Text>

        <Image src={IMAGES.Lilly2} alt="Lilly Sabri" mb={4} mx="auto" width={["100%", "100%", "50%"]} />

        <Heading fontSize={22} mb={3}>
          Guided Workout Classes:
        </Heading>

        <Text mb={3}>Get fit and follow along with Lilly in the LEAN app’s guided classes!</Text>

        <Box ml={4} mb={3}>
          <ul>
            <li>
              3 guides to suit you whatever your goal and ability:{" "}
              <strong>Hourglass Sculpt & Tone, Booty Pump & Lean Legs</strong> and{" "}
              <strong>Full Body Fat Loss & Abs!</strong>
            </li>
            <li>Workout at home or in the gym.</li>
            <li>Activations and finishers can be added to all classes to really get the most out of your workout.</li>
            <li>Play your favorite music on top of each class with our LEAN curated playlists</li>
            <li>Each class is time-stamped so you can jump to a section directly</li>
            <li>
              Very little equipment needed for fabulous results - you’ll only need a LEAN resistance band for most of
              the workouts!
            </li>
          </ul>
        </Box>

        <Heading fontSize={22} mb={3}>
          Personalized Meal Plans:
        </Heading>

        <Box ml={4} mb={4}>
          <ul>
            <li>
              We take all your information and calculate the calories you need to be consuming and make you a personal
              meal plan
            </li>
            <li>
              100’s of recipes for Omnivore, Vegan, Vegetarian & Pescatarians created by a top sports nutritionist.
            </li>
            <li>Switch meals, like meals and make it as custom as you like</li>
            <li>Optional calorie, macro and micro information on your plan - choose whatever is right for you!</li>
          </ul>
        </Box>

        <Heading fontSize={22} mb={3}>
          Content
        </Heading>

        <Text mb={4}>
          Get access to all content from classes, how to use LEAN equipment, Physio, Learn exercises, nutrition videos
          in the LEAN App. New content will be added all the time!
        </Text>

        <Heading fontSize={22} mb={3}>
          Physio:
        </Heading>

        <Box ml={4} mb={4}>
          <ul>
            <li>
              Library of how-to videos to make sure you’re performing movements correctly to avoid injury and get the
              most out of your sessions.
            </li>
            <li>Prevent and manage injuries with the Physio section of the app using Lilly’s years of expertise.</li>
          </ul>
        </Box>

        <Heading fontSize={22} mb={3}>
          Journaling & Habit Tracker:
        </Heading>

        <Box ml={4} mb={4}>
          <ul>
            <li>
              Journaling area where you’ll be able to keep track of your progress, write down your thoughts and feelings
              and see how far you’ve come to help you feel amazing!
            </li>
            <li>Habit tracker to help you pick and choose new habits you’d like to form and stick to!</li>
          </ul>
        </Box>

        <Heading fontSize={22} mb={3}>
          Store:
        </Heading>

        <Box ml={4} mb={4}>
          <ul>
            <li>Shop all the LEAN kit that you need to feel your level up your workouts & reach your goals faster.</li>
            <li>
              Find everything from resistance bands to pre workout and vegan protein powders with added superfoods with
              lots more to come...
            </li>
          </ul>
        </Box>

        <Heading fontSize={22} mb={3}>
          LET’S GO FAMILIA!
        </Heading>

        <Image src={IMAGES.Lilly3} alt="Lilly Sabri" mx="auto" width={["100%", "100%", "50%"]} />
        </>
    )
  },
];

const FaqAccordion = () => {
  return (
    <Accordion>
      {faqs.map((item, index) => (
        <AccordionItem key={index}>
          <AccordionItemHeading>
            <AccordionItemButton>{item.question}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {item.answer}
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default FaqAccordion;

